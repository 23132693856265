import { createSlice } from "@reduxjs/toolkit";

const validateInput = (expr) => {
  let exprLen = expr.length;
  let last = expr[exprLen - 1];
  let nlast = expr[exprLen - 2];
  let sndlast = expr[exprLen - 3];
  let digits = "01234567890";
  let ops = "+-*/";

  console.log("hello");
  switch (last) {
    case ".":
      switch (nlast) {
        case ".":
          console.log(expr.slice(0, -1));
          return expr.slice(0, -1);
          break;
        case "+":
        case "-":
        case "/":
        case "*":
          console.log(expr.slice(0, -1) + "0.");
          return expr.slice(0, -1) + "0.";
          break;

        default:
          console.log("dot default");
          for (let i = exprLen - 2; i >= 0; i--) {
            if (digits.includes(expr[i])) {
              console.log(expr[i], "digit");
              continue;
            }
            if (ops.includes(expr[i])) {
              console.log(expr, "hitops");
              return expr;
            }
            if (expr[i] === ".") {
              console.log(expr.slice(0, -1), "extrador");
              return expr.slice(0, -1);
            }
          }
          return expr;
          break;
      }
      break;
    case "0":
      switch (nlast) {
        case "+":
        case "-":
        case "/":
        case "*":
        case ".":
          console.log(expr, "opThenZero");
          if (exprLen === 2) {
            return "0";
          }
          return expr;
          break;

        default:
          console.log("zero default");
          for (let i = exprLen - 2; i >= 0; i--) {
            if (expr[i] === ".") {
              console.log(expr, "zero after dot");
              return expr;
            }
            if (ops.includes(expr[i])) {
              console.log(expr.slice(0, -1), "ops no dot or non zero");
              return expr.slice(0, -1);
            }
            if (expr[i] !== "0") {
              console.log(expr, "nonzero found");
              return expr;
            }
          }
          return expr;
          break;
      }
      break;
    case "+":
    case "/":
    case "*":
      console.log("in OPS");
      if (exprLen === 1) {
        return "0";
      }
      if (ops.includes(nlast)) {
        if (ops.includes(sndlast)) {
          return expr.slice(0, -3) + expr.slice(-1);
        }

        return expr.slice(0, -2) + expr.slice(-1);
      }
      if (nlast === ".") {
        return expr.slice(0, -2) + expr.slice(-1);
      }
      return expr;
      break;
    case "-":
      console.log("in OPS --");
      if (exprLen === 1) {
        console.log("in OPS ere--");
        return "-";
      }
      if (ops.includes(nlast)) {
        if (ops.includes(sndlast)) {
          return expr.slice(0, -2) + expr.slice(-1);
        }

        return expr;
      }
      console.log("bottom OPS ");
      return expr;
      break;
    default:
      console.log("in default ");
      if (nlast === "0") {
        for (let i = exprLen - 2; i >= 0; i--) {
          if (expr[i] === ".") {
            console.log(expr, "zero after dot");
            return expr;
          }
          if (ops.includes(expr[i])) {
            console.log(expr.slice(0, -1), "ops no dot or non zero");
            return expr.slice(0, -2) + expr.slice(-1);
          }
          if (expr[i] !== "0") {
            console.log(expr, "nonzero found");
            return expr;
          }
        }
      }

      return expr;
      break;
  }
};

export const calculateSlice = createSlice({
  name: "calculate",
  initialState: {
    value: 0,
    display: "0",
    total: 0,
  },
  reducers: {
    calcSolve: (state) => {
      let values = [""];
      console.log(values, "in");

      state.display.split("").forEach((element) => {
        if ("+-*/=".includes(element)) {
          values.push(element);
          values.push("");
          console.log(values, "op");
        } else {
          values[values.length - 1] += element;
          console.log(values, "val");
        }
      });
      console.log(values);
      console.log(values.join("#"));
      let total = 0;
      let index = 0;

      while (values.indexOf("") > 0) {
        let negative = values.indexOf("");
        values[negative + 2] = "-" + values[negative + 2].toString();
        values.splice(negative, 2);
      }
      console.log(values, typeof values, "after neg loop");

      while (values.indexOf("*") > 0 || values.indexOf("/") > 0) {
        let todo =
          Math.min(values.indexOf("*"), values.indexOf("/")) > 0
            ? Math.min(values.indexOf("*"), values.indexOf("/"))
            : Math.max(values.indexOf("*"), values.indexOf("/"));
        console.log(
          todo,
          "*/ loop\n",
          parseFloat(values[todo - 1]),
          parseFloat(values[todo + 1])
        );
        values[todo] === "*"
          ? (values[todo - 1] =
              parseFloat(values[todo - 1]) * parseFloat(values[todo + 1]))
          : (values[todo - 1] =
              parseFloat(values[todo - 1]) / parseFloat(values[todo + 1]));

        values.splice(todo, 2);
      }

      while (values.length > 1) {
        values[1] === "+"
          ? (values[0] = parseFloat(values[0]) + parseFloat(values[2]))
          : (values[0] = parseFloat(values[0]) - parseFloat(values[2]));
        values.splice(1, 2);
      }
      console.log(values, typeof values);

      return { display: values.join("#"), expr: values };
    },

    calcInputDigit: (state, action) => {
      if (state.display === "0") {
        state.display = validateInput(action.payload.toString());
      } else {
        state.display = validateInput(
          state.display + action.payload.toString()
        );
      }
    },
    calcInputDot: (state, action) => {
      if (state.display === "0") {
        state.display = "0.";
        return;
      }

      state.display = validateInput(state.display + action.payload.toString());
    },
    clearInput: (state) => {
      state.display = "0";
    },
  },
});

export const {
  calcInputDigit,
  calcInputDot,
  clearInput,
  calcSolve,
} = calculateSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectDisplay = (state) => state.calculate.display;

export default calculateSlice.reducer;
