import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectCount,
  selectDisplay,
  calcInputDigit,
  calcInputDot,
  clearInput,
  calcSolve,
} from "./calculateSlice";
import styles from "./Calculate.module.css";

export function Calculate() {
  const dispatch = useDispatch();

  const display = useSelector(selectDisplay);

  return (
    <div>
      <section>
        <div className={styles["grid-container"]}>
          <div className={styles.display} id="display">
            <div>{display}</div>
          </div>
          <div
            className={styles.clear}
            id="clear"
            onClick={() => dispatch(clearInput())}
          >
            A/C
          </div>
          <div
            className={styles.divide}
            id="divide"
            onClick={() => dispatch(calcInputDigit("/"))}
          >
            /
          </div>
          <div
            className={styles.multiply}
            id="multiply"
            onClick={() => dispatch(calcInputDigit("*"))}
          >
            *
          </div>
          <div
            className={styles.seven}
            id="seven"
            onClick={(e) => dispatch(calcInputDigit(7))}
          >
            7
          </div>
          <div
            className={styles.eight}
            id="eight"
            onClick={(e) => dispatch(calcInputDigit(8))}
          >
            8
          </div>
          <div
            className={styles.nine}
            id="nine"
            onClick={(e) => dispatch(calcInputDigit(9))}
          >
            9
          </div>
          <div
            className={styles.four}
            id="four"
            onClick={(e) => dispatch(calcInputDigit(4))}
          >
            4
          </div>
          <div
            className={styles.five}
            id="five"
            onClick={(e) => dispatch(calcInputDigit(5))}
          >
            5
          </div>
          <div
            className={styles.six}
            id="six"
            onClick={(e) => dispatch(calcInputDigit(6))}
          >
            6
          </div>
          <div
            className={styles.one}
            id="one"
            onClick={(e) => dispatch(calcInputDigit(1))}
          >
            1
          </div>
          <div
            className={styles.two}
            id="two"
            onClick={(e) => dispatch(calcInputDigit(2))}
          >
            2
          </div>
          <div
            className={styles.three}
            id="three"
            onClick={(e) => dispatch(calcInputDigit(3))}
          >
            3
          </div>
          <div
            className={styles.subtract}
            id="subtract"
            onClick={(e) => dispatch(calcInputDigit("-"))}
          >
            -
          </div>
          <div
            className={styles.add}
            id="add"
            onClick={(e) => dispatch(calcInputDigit("+"))}
          >
            +
          </div>
          <div
            className={styles.equals}
            id="equals"
            onClick={(e) => dispatch(calcSolve())}
          >
            =
          </div>
          <div
            className={styles.zero}
            id="zero"
            onClick={(e) => dispatch(calcInputDigit(0))}
          >
            0
          </div>
          <div
            className={styles.decimal}
            id="decimal"
            onClick={(e) => dispatch(calcInputDot("."))}
          >
            .
          </div>
        </div>
      </section>
    </div>
  );
}
